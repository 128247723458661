.strait-home {
    .inner {
        border: 10px solid #9F9784;
        border-radius: 30px;
        overflow: hidden;
    }

    .strait-home__left {
        background: white;
        color: #9F9784;
        padding: 30px;

      
        .strait-home__logo {
            width: 90px;
            margin: 0 auto 14px;
        }
    }

    .strait-home__content {
        font-family: "Gotham-Condensed";
        font-weight: 500;
        font-size: 18px;
        line-height: 1.388;
        margin-bottom: 50px;

        h2 {
            font-family: "old_stamper";
            font-size: 36px;
            font-weight: 400;
            margin-bottom: 8px;
        }

    }

    .strait-home__buttons {
        display: flex;
        flex-wrap: wrap;

        .button {
            min-width: 105px;
        }
        .button:not(:last-child) {
            margin-right: 5px;
        }
    }

    .strait-home__right {
        background: black;

        .strait-home__bg {
            width: 100%;
        }
    }


    @include breakpoint(large){
        .inner {
            display: flex;

            .strait-home__left {
                width: 32%;
                display: flex;
                flex-direction: column;

                .strait-home__content {
                    flex-grow: 1;
                }
            }

            .strait-home__right {
                width: 68%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

}