.menu.dropdown {
  > li {
      &.is-dropdown-submenu-parent {
          > a {
              padding-right: 0;

              &::after {
                  display: none;
              }
          }
      }

      //submenu
      ul {
          display: block;
          pointer-events: auto;
          visibility: hidden;
          opacity: 0;
          transform: translate(0, 10px);
          transition: opacity 0.3s ease-in, transform 0.3s ease-in, visibility 0.3s ease-in;
      }

      //active state            
      &.is-active {
         ul {
             opacity: 1;
             transform: translate(0, 0);
             visibility: visible;
         }
     }
  }
}

.primary-nav {
    background: #181818;
    display: none;

    @include breakpoint(medium){
      display: block !important;

      .submenu {
          opacity: 0;
      }
      ul ul {
        background: #181818;
        border-radius: 7px;
        text-align: center;
        min-width: 225px;
        margin-left: -75px;

        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 12px 15px 12px;
          border-color: transparent transparent white transparent;
          position: absolute;
          left: 50%;
          margin-left: -15px;
          top: -15px;
        }

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 13px 10px;
          border-color: transparent transparent #181818 transparent;
          position: absolute;
          left: 50%;
          margin-left: -13px;
          top: -13px;
        }
  
        li:not(:last-child) {
          border-bottom: 1px solid white;
        }
  
        a {
          font-size: 19px;
          padding-top: 15px !important;
          padding-bottom: 15px !important;
      
        }
      }
    }

   
}

.primary-nav::before,
.primary-nav::after {
    content: ' ';
    display: table;
}

.primary-nav::after {
    clear: both;
}

@media screen and (min-width: 40em) {
    .primary-nav {
        padding: 0 30px;
        margin-top: 50px;
        margin-bottom: 12px;
    }
}

.primary-nav__social {
    margin-left: 14px;
}

@media screen and (min-width: 40em) {
    .primary-nav__social {
        float: left;
        margin-left: 9px;
        margin-top: 30px;
    }
}

@media screen and (min-width: 40em) {
    .primary-nav__menu {
        float: left;
    }
}

@media screen and (min-width: 40em) {
    .primary-nav__menu>li {
        padding-right: 9px;
    }
}

@media screen and (min-width: 64em) {
    .primary-nav__menu>li {
        padding-right: 27px;
    }
}

.primary-nav__menu>li>a {
    font-family: "HelveticaCondensed";
    text-transform: uppercase;
    font-size: 0.875rem;
    transition: 0.5s;
}

@media screen and (min-width: 40em) {
    .primary-nav__menu>li>a {
        padding: 2.1rem 1rem 1.8rem;
        height: 100%;
        display: flex;
      align-items: center;
    }
}

@media screen and (min-width: 64em) {
    .primary-nav__menu>li>a {
        padding: 2.1rem 1.6rem 1.8rem;
        color: white !important;
    }
}
.primary-nav__menu a:focus,
.primary-nav__menu a:active {
  color: white !important;
}
.primary-nav__menu>li>a.current,
.primary-nav__menu>li:hover>a {
    background: #c03838 !important;

}

.primary-nav__menu>li>a>i {
    margin-right: 0;
}

@media screen and (min-width: 40em) {
    .primary-nav__menu>li>a.item-home {
        padding: 1.65rem 1.6rem;
    }
}

.dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
  border: inset 5px;
  border-color: #fff transparent transparent;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  border-color: #fff transparent transparent;
}