.special-offer {
    margin-top: 77px;

    .inner {
        background: #C03939;
        padding: 30px;
        text-align: center;

        @include breakpoint(medium){
            display: flex;
            text-align: left;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .special-offer__headline {
        font-weight: 600;
        font-size: 22px;
        line-height: 1.2;
        position: relative;
        padding-left: 60px;
        display: inline-block;

        &:before {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background: url(../images/homepage/disc.svg) no-repeat;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 0;
        }
        span {
            text-transform: uppercase;
           font-size: 30px;
           display: block;
        }

       

        @include breakpoint(medium){
            font-size: 30px;
            padding-left: 150px;
            margin-right: 30px;
            flex-grow: 1;

            span {
               font-size: 41px;
            }

            &:before {
                width: 100px;
                height: 100px;
            }
        }
    }

    .special-offer__price {
        font-size: 44px;
        font-weight: bold;
        color: black;
        width: 257px;
        min-width: 257px;
        height: 150px;
        background: url(../images/homepage/price-bg.svg) no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        line-height: 1;
        margin: 20px auto 0;

        @include breakpoint(medium){
            margin: 0;
        }
    }
}