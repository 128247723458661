.albums-grid {
    margin-top: 90px;
    .albums-grid__item {
        padding: 0 8px;
        margin-bottom: 16px;
        width: 50%;

        @include breakpoint(medium){
            width: 25%;
        }

        a {
            display: block;
            border: 15px solid white;

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .albums-grid__container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: -16px;
    }

    .albums-grid__footnote {
        text-align: center;
        margin-top: 36px;
        font-size: 18px;
        line-height: 1.2;

        @include breakpoint(medium){
            font-size: 25px;
        }
    }
}