.content-columns {

    .citate {
        color: #fff;
        border: none;
        padding: 0;
        font-style: italic;
        font-size: 32px;
        margin-top: 36px;
        line-height: 1.1;
    }

    .citate__author {
        color: #fff;
        font-style: normal;
        font-size: 1.125rem;
    }

    .citate__author:before {
        display: none;
    }

    .content {
        blockquote {
            font-style: italic;
            font-size: 33px;
            padding: 0;
            border: 0;
            color: white;
            margin: 0 0 34px;
            text-align: left;
        }

        picture {
            text-align: center;
            margin-bottom: 34px;
            h3 {
                text-transform: uppercase;
                color: #BD3939;
                font-size: 24px;
                font-weight: bold;
            }

            p {
                font-style: italic;
                line-height: 1.2;
                font-size: 14px;
            }

            img {
                margin-bottom: 20px;
            }

        }
    }
    @media screen and (min-width: 64em) {
        .citate__author {
            margin-bottom: 70px;
        }
    }

    @media screen and (min-width: 64em) {
        .content {
            padding-right: 59px;
            text-align: justify;
        }
    }

    @media screen and (min-width: 64em) {
        .content p {
            line-height: 2.4;
            font-size: 18px;
            margin-bottom: 34px;
        }
    }

    @media screen and (min-width: 64em) {
        .sidebar {
            padding-left: 17px;
            padding-top: 63px;
        }
    }

    .subscribe__form {
        padding: 8px 16px 21px;
        background: #fff;
        margin-bottom: 20px;
    }

    .subscribe__title {
        margin-bottom: 20px;
    }

    @media screen and (min-width: 64em) {
        .subscribe__form {
            margin-bottom: 53px;
        }
    }

    .subscribe__input {
        max-width: 252px;
        margin-bottom: 10px;
        font-size: 14px;
        padding: 0px 10px;
        height: 31px;
        border: 2px solid #d4d4d4;
    }

    .subscribe__submit {
        background: #1a1819;
        color: #aaaaaa;
        padding: 5px 22px;
        font-size: 19px;
        border: none;
        transition: 0.5s;
    }

    .subscribe__submit:hover {
        background: #353033;
    }


    .album-item {
        .album-item__image {
            margin-bottom: 20px;
        }

        .album-item__buttons {
            display: flex;
            flex-wrap: wrap;
            margin-left: -3px;
            margin-right: -3px;
            margin-bottom: -10px;

            .button {
                margin: 0 3px;
                margin-bottom: 10px;
                width: calc(100% - 6px);

                &.w50 {
                    width: calc(50% - 6px);
                }
            }

            .button+.button--secondary {
                margin-top: 10px;
            }
        }

        +.sidebar-title {
            margin-top: 55px;
            margin-bottom: 30px;
        }

        +.album-item {
            margin-top: 40px;
        }

        &--featured {
            background: #202020;
            padding: 20px;
            margin-left: -20px;
            border: 1px solid white;
        }
    }



    .contact-form {
        margin-bottom: 30px;
    }

    .contact-form__input {
        max-width: 365px;
        margin-bottom: 0;
    }

    .contact-form__row {
        margin-bottom: 20px;
    }

    .contact-form__input--textarea {
        max-width: initial;
        height: 245px;
    }

    .contact-form__submit {
        width: 130px;
        text-align: center;
        padding: 10px;
    }

    .contact-form label {
        font-size: 1rem;
    }

    .contact-form__row {
        margin-bottom: 20px;
    }

    .error {
        color: #c03838;
    }

    label {
        color: #fff;
    }

    .shop {
        display: flex;
        flex-wrap: wrap;
        margin-left: -25px;
        margin-right: -25px;
        margin-top: 80px;


        .shop__item {
            width: calc(100% - 50px);
            margin-left: 25px;
            margin-right: 25px;
            margin-bottom: 50px;

            .inner {
                max-width: 260px;

            }

            @include breakpoint(medium){
                width: calc(50% - 50px); 
            }

            @include breakpoint(large){
                width: calc(33.3333% - 50px); 
            }
        }

        .shop__img {
            margin-bottom: 20px;
        }

        .shop__name {
            margin-bottom: 5px;
            span {
                display: block;
            }
        }

        .shop__price {
            color: #C03939;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .shop__buttons {
            margin-left: -5px;
            margin-right: -5px;
            display: flex;
            flex-wrap: wrap;

            .button,
            form {
                margin: 0 5px;

                &.w50 {
                    width:calc(50% - 10px);
                }
            }

            .button--secondary {
                margin-top: 20px;
                white-space: nowrap;
            }
        }
    }

    .songs-container {
        margin-bottom: 34px;
    }

    .songs {
        padding: 15px 10px;
        background: #2C2E3B;
        border: 2px solid #444657;
        border-radius: 5px;

        .songs__item {
            border: 3px solid #444657;
            background:#333648;
            display: flex;
            border-radius: 5px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .songs__count {
                font-size: 24px;
                font-weight: bold;
                width: 45px;
                min-width: 45px;
                text-align: center;
                border-right: 3px solid #444657;
                padding: 5px 3px;
            }

            .song__title {
                flex-grow: 1;
                padding: 5px 18px;
                text-align: left;

                h2 {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1;
                }

                span {
                    font-size: 18px;
                    font-style: italic;
                }

                border-right: 3px solid #444657;
            }

            .songs__play {
                border-right: 3px solid #444657;
                width: 45px;
                min-width: 45px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .play-button {
                    display: block;
                    background: none;
                    margin-left: auto;
                    margin-right: auto;
                    width: 26px;
                    height: 26px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    cursor: pointer;

                    &--play {
                        background-image: url(../images/play.svg);
                    }

                    &--pause {
                        background-image: url(../images/pause.svg);
                    }
                }
            }

            .songs__buy {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 14px;
                width: 45px;
                min-width: 45px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                

                a {
                    color: #777A88;
                }
            }


        }
    }

}