.split-content {
    margin-top: 72px;

    .readmore {
        font-weight: bold;
        text-decoration: underline;
        display: inline-block;
        margin-top: 80px;

        &:hover {
            text-decoration: none;
        }
    }
}