.button {
    background: none;
    border: 3px solid white;
    padding: 11px 7px;
    text-align: center;
    display: inline-block;
    transition: $tr;
    font-size: 16px;
    font-weight: bold;

    &.button--small {
      font-size: 14px;
      padding: 14px 5px;
    }

    &--secondary {
        background: #BD3939;
        text-align: center;
        text-decoration: underline;
        padding: 20px 30px;
        border: 0;
        font-size: 18px;

        &.button--small {
          padding: 10px 10px;
        }

        &:hover {
          background: lighten(#BD3939, 0.2) !important;
        }
     
    }

   

    &:hover,
    &:focus {
      background: none;
      color: #dbdbdb;
      border-color: #cccccc;
    }

    &--solid {
      background: #C03939;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 900;
      font-family: "Gotham-Condensed";
      border-radius: 10px;
      transition: $tr;
      margin: 0;
      padding: 11px 23px;
  
      &:hover,
      &:focus {
          background: #a22020;
      }
    }
}