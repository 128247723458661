
.header-pic {
    max-width: 68.75rem;
    margin: 0 auto 20px;
    position: relative;
    text-align: center;
}

@media screen and (min-width: 64em) {
    .header-pic {
        text-align: left;
        margin-bottom: 77px;
    }
}

.header-pic__text {
    margin: 0 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

@media screen and (min-width: 64em) {
    .header-pic__text {
        bottom: initial;
        left: initial;
        right: 9.5%;
        top: 23.5%;
        margin: 0;
    }
}

.header-pic img {
    max-width: 100%;
}

@media screen and (min-width: 64em) {
    .header-pic--inner {
        margin-bottom: 30px;
    }
}