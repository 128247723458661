.latest-video {
    margin-top: 73px;
    .inner {
        max-width: 755px;
        margin-left: auto;
        margin-right: auto;
    }
}

.video {
    margin-top: 80px;

    .video__copy {
        margin-top: 50px;
        line-height: 1.8;

        p:not(:last-child) {
            margin-bottom: 40px;
        }

        a {
            color: #BD3939;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}