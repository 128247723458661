.join {
    text-align: center;
    margin-top: 40px;

    .section-title {
        color: white;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 40em) {
    .join {
        text-align: left;
        margin-bottom: 72px;
    }
}

.join__link {
    display: block;
    text-align: center;
    margin-top: 20px;
}

@media screen and (min-width: 40em) {
    .join__link {
        margin-top: 27%;
        margin-left: -53px;
    }
}

.join__link span {
    display: block;
    margin-bottom: 15px;
}