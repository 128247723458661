$img-margin: $module-margin / 2;
.alignleft,
.alignright,
.alignnone, 
.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
  float: none;
  height: auto;

  @include breakpoint(small only) {
    + p,
    p + & {
      @include responsive-margin('top', $module-margin);
    }
  }
}

.alignnone, 
.aligncenter {
  + p,
  p + & {
    @include responsive-margin('top', $module-margin);
  }
}

@include breakpoint(medium) {
    .alignleft {
        float: left;
        margin-right: rem-calc($img-margin);
        margin-bottom: rem-calc($img-margin);
    }
    .alignright {
        float: right;
        margin-left: rem-calc($img-margin);
        margin-bottom: rem-calc($img-margin);
    }

    .alignnone {
      margin-left: 0;
      margin-right: 0;
    }  
}