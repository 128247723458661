.page-footer {
    text-align: center;
    padding: 30px 0; }
    @media screen and (min-width: 40em) {
      .page-footer {
        margin-top: 50px; } }
    .page-footer__bottom {
      text-transform: uppercase;
      font-family: "HelveticaCondensed";
      font-size: 12px;
      color: #787878; }
    .page-footer__menu {
      display: inline-block;
      vertical-align: middle;
      margin: 0; }
      .page-footer__menu li {
        display: inline-block; }
      .page-footer__menu a {
        color: #787878;
        display: inline-block; }
        .page-footer__menu a:before {
          content: '|';
          display: inline-block;
          vertical-align: top;
          margin-top: -2px;
          padding-left: 5px;
          padding-right: 5px; }
        .page-footer__menu a.current, .page-footer__menu a:hover {
          color: #c03838; }
        .page-footer__menu a.current:before, .page-footer__menu a:hover:before {
          color: #787878; }
  
  .copyright {
    display: inline-block;
    vertical-align: middle; }
  
  .socials li {
    display: inline-block; }