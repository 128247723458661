/* font-family: "HelveticaRegular"; */
@font-face {
    font-family: "helvetica";
    src: url("../fonts/HelveticaRegular/HelveticaRegular.eot");
    src: url("../fonts/HelveticaRegular/HelveticaRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaRegular/HelveticaRegular.woff") format("woff"), url("../fonts/HelveticaRegular/HelveticaRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

/* font-family: "HelveticaBold"; */
@font-face {
    font-family: "helvetica";
    src: url("../fonts/HelveticaBold/HelveticaBold.eot");
    src: url("../fonts/HelveticaBold/HelveticaBold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaBold/HelveticaBold.woff") format("woff"), url("../fonts/HelveticaBold/HelveticaBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
}

/* font-family: "HelveticaLight"; */
@font-face {
    font-family: "helvetica";
    src: url("../fonts/HelveticaLight/HelveticaLight.eot");
    src: url("../fonts/HelveticaLight/HelveticaLight.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaLight/HelveticaLight.woff") format("woff"), url("../fonts/HelveticaLight/HelveticaLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "HelveticaCondensed";
    src: url("../fonts/HelveticaCondensed/Helvetica-Condensed.eot");
    src: url("../fonts/HelveticaCondensed/Helvetica-Condensed.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaCondensed/Helvetica-Condensed.woff") format("woff"), url("../fonts/HelveticaCondensed/Helvetica-Condensed.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "HelveticaCondensed";
    src: url("../fonts/HelveticaCondensedBold/HelveticaNeue-CondensedBold.eot");
    src: url("../fonts/HelveticaCondensedBold/HelveticaNeue-CondensedBold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaCondensedBold/HelveticaNeue-CondensedBold.woff") format("woff"), url("../fonts/HelveticaCondensedBold/HelveticaNeue-CondensedBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "HelveticaCondensed";
    src: url("../fonts/HelveticaCondensedBlack/HelveticaNeue-CondensedBlack.eot");
    src: url("../fonts/HelveticaCondensedBlack/HelveticaNeue-CondensedBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaCondensedBlack/HelveticaNeue-CondensedBlack.woff") format("woff"), url("../fonts/HelveticaCondensedBlack/HelveticaNeue-CondensedBlack.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: "BickhamScriptPro";
    src: url("../fonts/BickhamScriptPro-Regular.otf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "old_stamper";
    src: url("../fonts/old_stamper.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Gotham-Condensed";
    src: url("../fonts/Gotham-Condensed-Medium.otf") format("opentype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "Gotham-Condensed";
    src: url("../fonts/Gotham-Black.otf") format("opentype");
    font-style: normal;
    font-weight: 900;
}



.icon {
    display: inline-block;
    background-image: url(../images/spritesheet.png);
    text-indent: -10000px;
    margin: 0;
}

.icon-facebook {
    width: 22px;
    height: 22px;
    background-position: -134px -5px;
}

.icon-facebook-big {
    width: 42px;
    height: 42px;
    background-position: -134px -38px;
}

.icon-home {
    width: 23px;
    height: 23px;
    background-position: -166px -5px;
}

.icon-amazon {
    width: 119px;
    height: 60px;
    background-position: -5px -5px;
}

.icon-facebook-f {
    width: 119px;
    height: 60px;
    background-position: -5px -75px;
}

.icon-itunes {
    width: 119px;
    height: 60px;
    background-position: -5px -145px;
}

.icon-spotify {
    width: 119px;
    height: 60px;
    background-position: -199px -5px;
}

.icon-youtube {
    width: 119px;
    height: 60px;
    background-position: -186px -75px;
}

.icon-check {
    width: 24px;
    height: 24px;
    background-position: -144px -96px;
    vertical-align: middle;
}

.icon-delete {
    width: 16px;
    height: 18px;
    background-position: -148px -132px;
}

.uppercase {
  text-transform: uppercase;
}


.section-title {
  font-size: 34px;
  line-height: 1.19;
  font-weight: 600;
  margin-bottom: 44px;
  text-align: left;

  @include breakpoint(medium){
    font-size: 42px;
  }

  &--h1-small {
      font-size: 28px;

      @include breakpoint(medium){
        font-size: 36px;
      }
  }

  &--h2 {
    font-size: 26px;
    color: #C03939;
    font-family: "HelveticaCondensed";
  }

  + .section-subtitle {
      margin-top: -20px;
  }
}

.section-subtitle {
    font-size: 16px;
    font-weight: bold;

}